import '@/styles/globals.css'
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';

import Layout from '../components/layout';
import { AuthProvider } from 'react-oidc-context';
import AxiosInterceptor from '@/request';
import { getOidcConfig } from '@/services/auth.service';
import { Box } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'

export default function App({Component, pageProps}) {
    const oidcConfig = getOidcConfig();

    return (
        <AuthProvider {...oidcConfig}>
            <AxiosInterceptor>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <Box height="100vh">
                        <Layout>
                            <Component {...pageProps} />
                        </Layout>
                    </Box>
                </LocalizationProvider>
            </AxiosInterceptor>
        </AuthProvider>
    )
}
