import request from './request';
import { AxiosError } from 'axios';
import { useAuth } from 'react-oidc-context';
import { useUnmount } from 'react-use';
import { signIn } from '@/services/auth.service';

export default function AxiosInterceptor({children}) {
    const auth = useAuth();

    const requestInterceptor = request.interceptors.request.use(async (requestConfig) => {
        const token = auth.user?.id_token;

        if (!requestConfig.headers.authorization) {
            requestConfig.headers.authorization = `Bearer ${token}`;
        }
        return requestConfig;
    });

    const errorInterceptor = request.interceptors.response.use(res => res, async err => {
        if (err?.response?.status === 401) {
            console.log('Got 401. opening login');
            try {
                await signIn(auth);
                return;
            } catch (err) {
                console.log(err);
            }
            if (auth.isAuthenticated) {
                return request.request(err.config);
            } else {
                return Promise.reject(err);
            }
        } else if (err?.response?.status === 400) {
            // toast.error(err.response.data?.message || 'שגיאה!');
        }

        return Promise.reject(err);
    });

    useUnmount(() => {
        request.interceptors.response.eject(requestInterceptor);
        request.interceptors.response.eject(errorInterceptor);
    });

    return children;
}
