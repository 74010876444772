import { Grid } from '@mui/material';
import LoginButton from '@/components/login-button';

export default function CenterScreen({children}) {
    return <Grid container
        spacing={0}
        direction="column"
        alignItems="center"
        justifyContent="center"
        sx={{minHeight: '100vh'}}>
        <Grid item xs={3}>
            {children}
        </Grid>
    </Grid>
}
