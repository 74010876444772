import Navbar from './navbar'
import { useAuth } from 'react-oidc-context';
import { CircularProgress, Grid } from '@mui/material';
import LoginButton from '@/components/login-button';
import CenterScreen from '@/components/center-screen';

export default function Layout({children}) {
    const auth = useAuth();

    if (auth.isLoading) {
        return <CenterScreen>
            <CircularProgress/>
        </CenterScreen>;
    }

    return (
        <Navbar>
            {auth.isAuthenticated ? children : <CenterScreen>
                <LoginButton/>
            </CenterScreen>}
        </Navbar>
    )
}
