import { useAuth } from 'react-oidc-context';
import { Box, Button, Typography } from '@mui/material';
import { signIn } from '@/services/auth.service';

export default function LoginButton() {
    const auth = useAuth();

    // let text: string;
    //
    // switch (auth.activeNavigator) {
    //     case "signinSilent":
    //         return <div>Signing you in...</div>;
    //     case "signoutRedirect":
    //         return <div>Signing you out...</div>;
    // }
    //
    // if (auth.isLoading) {
    //     return <div>Loading...</div>;
    // }
    //
    // if (auth.error) {
    //     return <div>Oops... {auth.error.message}</div>;
    // }

    if (auth.isAuthenticated) {
        return (
            <Box>
                <Typography display="inline" mr={2}>Hello {auth.user?.profile.email}</Typography>
                <Button variant="outlined" color="inherit" onClick={() => void auth.signoutRedirect()}>Log out</Button>
            </Box>
        );
    }

    return <Button variant="outlined" onClick={() => signIn(auth)}>Log in</Button>;
}
