import axios from 'axios';

const instance = axios.create({
    // withCredentials: true,
    baseURL: process.env.NEXT_PUBLIC_SERVER_URL
});

// instance.defaults.withCredentials = true;

export default instance;
